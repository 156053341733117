import React, { useState, useEffect } from 'react';
import {
	Button
} from 'reactstrap';

export default function WritingResult(props) {
	const [result, setResult] = useState(null);
	const [buttons, setButtons] = useState(false);
	const [isEssay, setIsEssay] = useState(false);
	
	const validKeys = [
		"content",
		"form",
		"grammar",
		"grammer",
		"vocabulary",
		"linguistic",
		"structure",
		"spelling",
		"overall"
	];

	useEffect(() => {
		const { resultData, buttonsOnly, essay } = props;
		setResult(resultData);
		setButtons(buttonsOnly);
		setIsEssay(essay);
	}, []);


	const errorMessage = () => {
		if (result.content.score === 0) {
			return (
				<div className="error-message alert-danger">
					{result.feedback.content}
				</div>
			);
		} else if (result.form.score === 0) {
			return (
				<div className="error-message alert-danger">
					{result.feedback.form}
				</div>
			);
		}
	}

	return (
		<>
			{/* Show scores for each parameters and overall. */}
			{!buttons && result !== null &&
				<div className="scoreWrap">
					{Object.keys(result).map((item, i) => {
						return (
							<React.Fragment key={i}>
								{validKeys.includes(item) &&
									<div className={`col-xs-12 col-sm-6 col-lg-3 mb-5 d-flex flex-column justify-content-center align-items-center ${item === 'overall' ? 'total-score-box' : ''}`} key={i}>
										<h4>
											<span className={result[item].score === 0 ? 'text-danger' : (result[item].score > 0 && result[item].score < result[item].out_of ? 'text-orange' : 'text-success')}>{result[item].score}/{result[item].out_of}</span>
										</h4>
										<h3 className="m-0">{result[item].label}</h3>
									</div>
								}
							</React.Fragment>
						)
					})}
				</div>
			}

			{/* Show error message only. If any. Only Essay */}
			{isEssay &&
				errorMessage()
			}

			{/* Show 3 Buttons. Only essay. */}
			{buttons &&
				<div className="col-12 essay-data-buttons text-center">
					<Button
						type="button"
						className="total-score not-round"
					>Total Score: {result.overall.score}</Button>

					<Button
						type="button"
						className="english-style not-round"
					>{result.english_pattern} English</Button>

					<Button
						type="button"
						className="word-count not-round"
					>Word Count: {result.word_count}</Button>
				</div>
			}
		</>	
	);
}